import React from 'react';
import PostCard from '../components/PostCard';
import styled from 'styled-components';

// Styled components
const RecentPostsHeading = styled.h2`
	margin-bottom: 0;
	padding: 2rem 1rem 0 1rem;
`;

const RecentPostsSection = styled.section`
	display: flex;
		flex-wrap: wrap;
	min-height: 50vh;
	padding-top: 1rem;

	.post-card { width: calc(33.3% - 1rem); }
	& > p { 
		max-width: var(--line-length);
		padding: 0 1rem;
		width: 100%;
	}

	@media (max-width: 960px) {
		.post-card { width: calc(50% - 1rem); }
	}

	@media (max-width: 620px) {
		.post-card { width: 100%; }
	}
`;

// Functional components
const RecentPosts = props => (
	<article>
		<RecentPostsHeading>Recent Blog Posts</RecentPostsHeading>
		<RecentPostsSection id="recent" style={props.style}>
			{props.posts.map(post => {
				return <PostCard body={post.body} key={post.id} postedAt={post.postedat} title={post.title} />
			})}
		</RecentPostsSection>
	</article>
);

export default props => (<RecentPosts posts={props.posts}/>);