import React from 'react';
import styled from 'styled-components';
import { Context } from './Context';

// Styled components
const BannerLeft = styled.div`
  padding-right: 1.25rem;
  width: 66%;
`;

const BannerRight = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  width: 33%;

  img {
    display: block;
    filter: drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.45));
    max-width: 835px;
    transition: transform 0.5s linear;
    width: 100%;
  }

  img.rotate {
    transform: rotate(-7deg);
  }

  @media (max-width: 620px) {
    min-height: 200px;
  }
`;

const BannerSection = styled.section`
  border-bottom: 2px solid var(--trans-black);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-size: calc(22px + 0.25vw);
  padding: 1rem;

  h1 {
    font-size: 4rem;
    line-height: 3.5rem;
  }

  @media (max-width: 620px) {
    flex-direction: column-reverse;
    div {
      margin: 0;
      width: 100%;
    }
  }
`;

// Functional Components
const Banner = () => (
  <Context.Consumer>
    {({ headerCollapsed }) => (
      <BannerSection>
        <BannerLeft>
          <h1>Brandon Ubben</h1>
          <p>
            Web developer, car enthusiast,<br/>music fan &mdash; among other things.
          </p>
        </BannerLeft>
        <BannerRight>
          <img
            src="https://images.ubben.co/eyeglasses.png"
            alt="Ubben.co"
            className={headerCollapsed ? "rotate" : ""}
          />
        </BannerRight>
      </BannerSection>
    )}
  </Context.Consumer>
);

export default Banner;
