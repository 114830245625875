import React from 'react';
import Parser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import makeDate from '../utils/makeDate';
import makeSlug from '../utils/makeSlug';

// Import styling components
import styled from 'styled-components';
import {navigate} from '@reach/router';

// Styled components
const PostCardContainer = styled.article`
	border: var(--border-trans-black);
	border-radius: 1px;
	box-shadow: var(--shadow);
	box-sizing: border-box;
	display: block;
	font-size: 1rem;
	line-height: 1.25rem;
	margin: 0.5rem;
	height: calc(300px + 0.25vw);
	overflow: hidden;
	padding: 0rem 0.75rem;
	position: relative;
	transition: all 200ms linear;
	width: 100%;

	a {
		color: var(--blue);
		text-decoration: none;
	}
	a:hover { text-decoration: underline; }

	&:hover {
		box-shadow: 1px 3px 11px rgba(0, 0, 0, 0.3);
		cursor: pointer;
		transform: scale(1.025);
	}

	@media (max-width: 620px) {
		margin: 0.5rem auto;
	}
`;

const PostCardDate = styled.p`
	color: rgba(0, 0, 0, 0.5);
	font-size: 0.9rem;
	font-weight: bold;
	margin-bottom: 1.5rem;
	padding-left: 0.25rem;
	word-spacing: 1px;
`;

const PostCardHeading = styled.strong`
	font-size: calc(19px + 0.25vw);
`;

const PostCardTitle = styled.h4`
	line-height: 2.15rem;
	margin-bottom: 0;
`;

// Parser options
const parserOpts = {
	replace: ({name, children}) => {
		if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(name)) {
			return (
				<PostCardHeading>{domToReact(children, parserOpts)}</PostCardHeading>
			);
		} else if (name === 'img' || name === 'figcaption') {
			return React.createElement('span', {}, '');
		} return;
	}
};

export default props => (
	<PostCardContainer onClick={props.title !== 'Recent Posts' ? () => navigate(makeSlug('blog', props.title)) : ''} role="link" tabIndex="0" className="post-card" style={props.style}>
		<PostCardTitle>{props.title}</PostCardTitle>
		<PostCardDate>{makeDate(props.postedAt)}</PostCardDate>
		{Parser(props.body.replace('&mdash;', '-').slice(0, 450).trim() + '...', parserOpts)}
	</PostCardContainer>
);
