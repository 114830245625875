import React from "react";
import Layout from './../components/Layout';
import Banner from './../components/Banner';
import RecentPosts from './../components/RecentPosts'
import {graphql} from 'gatsby';

// Data
export const query = graphql`
query {
  cms {
    posts(first: 3, orderBy: postedat_DESC) {
      id
      title
      body
      postedat
    }
  }
}
`;

export default ({data}) => (
  <Layout pageTitle="Brandon Ubben &ndash; Web Developer" view="/">
    <Banner/>
    <RecentPosts posts={data.cms.posts}/>
  </Layout>
);
